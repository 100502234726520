import React, { useEffect, useState } from 'react'
import VerifiedStats from '../Csr/VerifiedStats'
import { ClientService } from '../../component/services/client.service'
import toast from 'react-hot-toast'
import Select from "react-select"
import moment from 'moment'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import CsrStatsIndex from '../Csr/CsrStats.index'
export default function Dashboard() {

    const [selectedValue, setSelectedValue] = useState<any>()
    const [shifts, setShifts] = useState<any>()
    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()

    const [centers, setCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
                setShiftDate(res.data.shifts.map((data: any) => {
                    return {
                        label: moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectedValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter(selectedValue).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getUniqueState = async () => {
        await ClientService.getUniqueState(selectedValue).then(res => {
            if (res.status === 200) {
                setStates(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        await ClientService.getUniqueCity(selectedValue).then(res => {
            if (res.status === 200) {
                setDistricts(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    console.log(selectedShiftDate, "shiftdate")

    const getShiftByShiftDate = () => {
        return shifts?.filter((shift: any) => shift?.value === selectedShiftDate?.value);
    };

    console.log(getShiftByShiftDate(), "getShiftByShiftDate")



    useEffect(() => {
        if (selectedValue) {
            getAllZones();
            getUniqueCenter();
            getUniqueCity();
            getUniqueState();
        }
    }, [selectedValue])

    useEffect(() => {
        getAllShift();
    }, [])


    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="d-flex text-secondary mt-3">Dashboard</h4>
                <div className="d-flex justify-content-start gap-3">
                    <Select
                        options={shiftDate}
                        onChange={(e: any) => setSelectedShiftDate(e)} // Store full object
                        placeholder="Select shift Date"
                        isClearable
                    />
                    <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectedValue(e?.value)}
                        placeholder="Select shift"
                        isClearable
                    />
                </div>

            </div>
            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Zones
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(zones?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedZones(
                                                zones?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedZones(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(states?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedStates(
                                                states?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedStates(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedStates}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />

                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(districts?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedDistricts(
                                                districts?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedDistricts(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedCenters(
                                                centers?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedCenters(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <div className="mt-3">
                <VerifiedStats
                    shiftId={selectedValue}
                    selectedCenters={selectedCenters}
                    selectedZones={selectedZones}
                    selectedDistrict={selectedDistricts}
                    selectedStates={selectedStates}
                    states = {states}
                />
            </div>

            <div className="mt-3 mb-3">
                <CsrStatsIndex shift={selectedValue} />
            </div>

        </>
    )
}
