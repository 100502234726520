import { time } from "console";
import makeRequest, { makeParams } from "../api/makeRequest";
import { RequestMethods } from "../api/requestMethode";
import url from "../api/urls";

export class ClientService {
    static async getAllShifts() {
        return await makeRequest(url.getAllShifts, RequestMethods.GET);
    }

    static async getCountInfo(shiftId: any) {
        return await makeRequest(url.stats.getCountInfo + "/" + shiftId, RequestMethods.GET);
    }


    static async getVerificationData(shiftId: any, pageNumber: any, pageSize: any, payload: any) {
        const params = makeParams([
            {index:'pageNumber', value:pageNumber},
            {index:'pageSize', value:pageSize},
            // {index:'searchText', value:searchText}
        ])
        return await makeRequest(url.client.getVerificationData + "/" + shiftId + params, RequestMethods.POST, payload);
    }

    static async getCenterCount(shiftId: any) {
        return await makeRequest(url.client.getCenterCount + "/" + shiftId, RequestMethods.GET);
    }

    static async getCenterStats(shiftId: any, centerPreference: any, zone: any, state: any, city: any) {
        const params = makeParams([
            {
                index: "centerPreference",
                value: centerPreference
            },
            {
                index: "zone",
                value: zone
            },

            {
                index: "state",
                value: state
            },
            {
                index: "city",
                value: city
            },
        ])
        return await makeRequest(url.client.getCenterStats + "/" + shiftId + params, RequestMethods.GET);
    }

    static async getCenterStatsDetails(shiftId: any, centerId: any) {
        return await makeRequest(url.client.centerStatsDetails + "/" + shiftId + "/" + centerId, RequestMethods.GET)
    }


    static async getUniqueCity(shiftId: any) {
        return await makeRequest(url.client.getUniqueCity + "/" + shiftId, RequestMethods.GET);
    }
    static async getUniqueCenter(shiftId: any) {
        return await makeRequest(url.client.getUniqueCenter + "/" + shiftId, RequestMethods.GET);
    }
    static async getUniqueZone(shiftId: any) {
        return await makeRequest(url.client.getUniquesZone + "/" + shiftId, RequestMethods.GET);
    }
    static async getUniqueState(shiftId: any) {
        return await makeRequest(url.client.getUniqueState + "/" + shiftId, RequestMethods.GET);
    }

    static async getUsers(shiftId: any, pageSize: any, pageNumber: any, payload: any) {
        const params = makeParams([{ index: "pageNumber", value: pageNumber }, { index: "pageSize", value: pageSize }]);
        return await makeRequest(url.client.getUsers + "/" + shiftId + params, RequestMethods.POST, payload)
    }

    static async getUsersData(shiftId: any, pageSize: any, pageNumber: any, payload: any) {
        const params = makeParams([{ index: "pageNumber", value: pageNumber }, { index: "pageSize", value: pageSize }]);
        return await makeRequest(url.client.getUserData + "/" + shiftId + params, RequestMethods.POST, payload)
    }

    static async downloadVerifiedZip(shiftId: any) {
        return await makeRequest(url.client.downloadVerifiedZip + "/" + shiftId, RequestMethods.GET);
    }

    static async searchStudentByRollNo(shiftId: any, rollNo: any) {
        const params = makeParams([{ index: "search", value: rollNo }]);
        return await makeRequest(url.client.searchByRollNo + "/" + shiftId + params, RequestMethods.GET);
    }

    static async getDatabyUserId(shiftId: any, userId: any) {
        return await makeRequest(url.client.getDatabyUserId + "/" + shiftId + "/" + userId, RequestMethods.GET)
    }

    static async getAllCSRReport(shiftId: any, pageSize: number, pageNumber:number) {
        const params = makeParams([
            {
                index: "pageSize",
                value: pageSize
            },
            {
                index: "pageNumber",
                value: pageNumber
            },
        ])
        return await makeRequest(url.client.getAllCSRReport + "/" + shiftId + params, RequestMethods.GET)
    }

    static async getVerificationEnums() {
        return await makeRequest(url.getVerificationEnums, RequestMethods.GET)
    }

    static async getAllBypassData(shiftId: any, pageSize: number, pageNumber:number, payload: any) {
        const params = makeParams([
            {
                index: "pageSize",
                value: pageSize
            },
            {
                index: "pageNumber",
                value: pageNumber
            },
        ])
        return await makeRequest(url.client.getAllBypassData + "/" + shiftId + params, RequestMethods.POST, payload)
    }

    static async getDropdownData(shiftId:any) {
        return await makeRequest(url.client.getDropdownData + "/" + shiftId, RequestMethods.GET)
    }

    static async getInvigilators(shiftId: any, pageNumber:number, pageSize:number, payload:any) {
        const params = makeParams([
            {index:'pageNumber', value:pageNumber},
            {index:'pageSize', value:pageSize},
            // {index:'searchText', value:searchText}
        ])
        return await makeRequest(url.client.getInvigilators + "/" + shiftId + params, RequestMethods.POST, payload)
    }

    static async getInvigilatorStats(shiftId: any, payload: any) {
        return await makeRequest(url.stats.getInvigilatorStats + "/" + shiftId, RequestMethods.POST, payload)
    }

    static async getVerifiedCount(shiftId:any, payload:any) {
        return await makeRequest(url.stats.getVerifiedCount + "/" + shiftId, RequestMethods.POST, payload)
    }

    static async getVerificationCountStats(shiftId:any, payload: any) {
        return await makeRequest(url.stats.getVerificationCountStats + "/" + shiftId , RequestMethods.POST, payload)
    }

    static async getVerificationByState(shiftId:any, payload:any) {
        return await makeRequest(url.stats.getVerificationByState + "/" + shiftId, RequestMethods.POST, payload)
    }

}