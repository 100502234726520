import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faFingerprint, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from "react-select"
import { StatsForCenterFailure, StatsForRegisteredDevice, UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import { CentersStats, InvigilatorStats, UserStats } from '../../component/Stats/Stats';
import FilterTable from '../../component/table/filter.table';
import TablePaginationComponent from '../../component/table/TablePagination';
import { saveAsCSV } from '../../component/helpers/csvhelper';
import moment from 'moment';

const FilterIndex = () => {

    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [centers, setCenters] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();
    const [selectedVerification, setSelectedVerification] = useState<any>();
    const [tableData, setTableData] = useState<any>();
    const [userData, setUserData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);

    const [verificationEnums, setVerificationEnums] = useState<any>();
    const [csvLoading, setCSVLoading] = useState<boolean>(false);

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()


    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalCount, setTotolCount] = useState<number>(0);


    const getVerificationEnums = async () => {
        await ClientService.getVerificationEnums().then(res => {
            if (res.status === 200) {
                setVerificationEnums(Object.values(res.data.enums).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
                setShiftDate(res.data.shifts.map((data: any) => {
                    return {
                        label: moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter(selectValue).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueState = async () => {
        await ClientService.getUniqueState(selectValue).then(res => {
            if (res.status === 200) {
                setStates(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        await ClientService.getUniqueCity(selectValue).then(res => {
            if (res.status === 200) {
                setDistricts(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const handleGetExamData = async () => {
        try {
            if (selectValue) {
                await Promise.all([
                    getAllZones(),
                    getUniqueCenter(),
                    getUniqueCity(),
                    getUniqueState(),
                ]);
            }
        } catch (err: any) {
            toast.error(err.response?.data || err.message || "An error occurred.");
        }
    };

    const getTableData = async () => {
        try {
            const payload = {
                zone: selectedZones?.map((zone: any) => zone.value) || [],
                district: selectedDistricts?.map((dist: any) => dist.value) || [],
                centers: selectedCenters?.map((center: any) => center.value) || [],
                state: selectedStates?.map((state: any) => state.value) || [],
                verificationMethods: selectedVerification?.map((state: any) => state.value) || [],
            };
            const res = await ClientService.getUsers(selectValue, pageSize, pageNumber, payload);
            if (res.status === 200) {
                setTableData(res.data);
                setTotolCount(res.data.totalUsers || 0);
            }
        } catch (err: any) {
            toast.error(err.response?.data || err.message || "An error occurred.");
        }
    };


    const getUserData = async () => {
        const payload = {
            zone: selectedZones && selectedZones.length > 0 ? selectedZones.map((zone: any) => zone.value) : [],
            city: selectedDistricts && selectedDistricts.length > 0 ? selectedDistricts.map((dist: any) => dist.value) : [],
            center: selectedCenters && selectedCenters.length > 0 ? selectedCenters.map((center: any) => center.value) : [],
            state: selectedStates && selectedStates.length > 0 ? selectedStates.map((state: any) => state.value) : [],
            methods: selectedVerification && selectedVerification.length > 0 ? selectedVerification.map((state: any) => state.value) : [],
        }
        setLoading(true)
        await ClientService.getUsersData(selectValue, pageSize, pageNumber, payload)
            .then(res => {
                if (res.status === 200) {
                    setUserData(res.data.students);
                    setTotolCount(res.data.total || 0);
                }
            }).catch(err => {
                toast.error(err.response.data);
            })
            setLoading(false)
    }


    async function downloadCsv() {
        setCSVLoading(true);
        try {
            const res = await ClientService.getUsersData(selectValue, 999999, 1, {
                zone: selectedZones?.map((zone: any) => zone.value) || [],
                city: selectedDistricts?.map((dist: any) => dist.value) || [],
                center: selectedCenters?.map((center: any) => center.value) || [],
                state: selectedStates?.map((state: any) => state.value) || [],
                methods: selectedVerification?.map((state: any) => state.value) || [],
            });

            if (res.status === 200) {
                const csvData = [
                    'Name,Roll No,State,City,Center',
                    ...res.data.students.map((student: any) =>
                        `${student.name},${student.rollNo},${student.state},${student.city},${student.centerPreference}`
                    ),
                ].join('\n');
                saveAsCSV(csvData, 'center-data');
            }
        } catch (err: any) {
            console.error(err);
            toast.error(err.response?.data || err.message);
        } finally {
            setCSVLoading(false);
        }
    }

    useEffect(() => {
        if (selectValue) {
            getUserData();
        }
    }, [pageSize, pageNumber, selectedZones, selectValue, selectedDistricts, selectedCenters, selectedStates]);



    useEffect(() => {
        if (selectValue) {
            handleGetExamData();
            getTableData();
        }
    }, [selectValue])

    useEffect(() => {
        getAllShift();
        getVerificationEnums();
    }, [])


    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-5">

                <h4 className="d-flex text-secondary">Center Data</h4>
                <div className="d-flex">
                    <Select
                        options={shiftDate}
                        onChange={(e: any) => setSelectedShiftDate(e)}
                        placeholder="Select shift Date"
                        isClearable
                    />
                    <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectValue(e?.value)}
                        placeholder="Select shift"
                        className="ms-3"
                        isClearable
                    />
                </div>
            </div>
            <div>
                <Card
                    className="mt-3 mb-3"
                    style={{
                        backgroundColor: "#f9f9f9",
                        borderRadius: "12px",
                        border: "none",
                    }}
                >
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Zones
                                    </Form.Label>
                                    <Select
                                        options={zones}
                                        onChange={setSelectedZones}
                                        value={selectedZones}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        States
                                    </Form.Label>
                                    <Select
                                        options={states}
                                        onChange={setSelectedStates}
                                        value={selectedStates}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Districts
                                    </Form.Label>
                                    <Select
                                        options={districts}
                                        onChange={setSelectedDistricts}
                                        value={selectedDistricts}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Centers
                                    </Form.Label>
                                    <Select
                                        options={centers}
                                        onChange={setSelectedCenters}
                                        value={selectedCenters}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Verification Type
                                    </Form.Label>
                                    <Select
                                        options={verificationEnums}
                                        onChange={setSelectedVerification}
                                        value={selectedVerification}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {tableData &&
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <Row>
                            <Col md={4}>
                                <UserStats statsData={tableData && tableData.chart3} />
                            </Col>
                            <Col md={4}>
                                <CentersStats statsData={tableData && tableData.chart2} />
                            </Col>
                            <Col md={4}>
                                <InvigilatorStats statsData={tableData && tableData.chart1} />
                            </Col>
                        </Row>
                    </div>
                }
                {tableData && <Row>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h4 className="text-secondary">Center Data</h4>
                        <Button className="ms-2" size="sm" onClick={downloadCsv} disabled={csvLoading}>
                            {
                                csvLoading ? <span>Downloading... <Spinner animation="border" size="sm" /> </span> : "Download CSV"
                            }
                        </Button>
                    </div>
                    {loading &&
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <Spinner animation="border" />
                        </div>
                    }
                    <Col>
                        <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                            <Card.Body>
                                <FilterTable
                                    tableData={userData}
                                    shiftId={selectValue}
                                />
                                <TablePaginationComponent
                                    currentPage={pageNumber}
                                    itemsCount={totalCount}
                                    itemsPerPage={pageSize}
                                    setItemsPerPage={(e: any) => setPageSize(e)}
                                    setCurrentPage={setPageNumber}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
            </div>
        </>
    )
}

export default FilterIndex