const url = {
    login: "/client/clientLogin",
    getAllShifts: "/client/getAllExamShift",
    getVerificationEnums: "/client/getVerificationEnums",


    stats: {
        getCountInfo: "/client/countInfo",
        getInvigilatorStats: "/client/stats",
        getVerifiedCount: "/client/verifiedCountUsers",
        getVerificationCountStats:"/client/getVerificationCountStats",
        getVerificationByState:"/client/getVerificationByState"
    },

    client: {
        getVerificationData: "/client/getVerification",
        getUniqueCity: "/client/uniqueCities",
        getUniqueState: "/client/uniqueStates",
        getUniquesZone: "/client/uniqueZones",
        getUniqueCenter: "/client/uniqueCenters",
        getUsers: "/client/getUsers",
        getUserData: "/client/getUsersData",
        downloadVerifiedZip: "/client/verifiedZip",
        searchByRollNo: "/client/user/search",
        getDatabyUserId: "/client/getDatabyUserId",
        getCenterCount: "/client/centerCount",
        getCenterStats: "/client/centerStats",
        centerStatsDetails:"/client/centerStatsDetails",
        getAllCSRReport: "/client/CSRReports",
        getAllBypassData: "/client/user/bypass",
        getDropdownData: "/client/dropdown",
        getInvigilators: "/client/invigilators",
    }



};

export default url;
