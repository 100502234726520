import { faUser, faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { UsersCard } from '../../component/Card/Statistics.card'
import Select from 'react-select'
import { ClientService } from '../../component/services/client.service'
import toast from 'react-hot-toast'
import VerifiedStats from './VerifiedStats'
import moment from 'moment'


interface ICSRStats {
    shift: any
}

export default function CsrStatsIndex(props: ICSRStats) {

    const [shifts, setShifts] = useState<any>();
    const [selectValue, setSelectValue] = useState<any>();
    const [countData, setCountData] = useState<any>();

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()



    // const getAllShift = async () => {
    //     await ClientService.getAllShifts().then(res => {
    //         if (res.status === 200) {
    //             setShifts(res.data.shifts.map((data: any) => {
    //                 return {
    //                     label: data.shiftName,
    //                     value: data._id
    //                 }
    //             }))
    //             setShiftDate(res.data.shifts.map((data: any) => {
    //                 return {
    //                     label: moment(data.startTime).format("DD MMM YYYY"),
    //                     value: data._id
    //                 }
    //             }))
    //         }
    //     }).catch(err => {
    //         toast.error(err.response.data);
    //     })
    // }

    const getCountData = async () => {
        await ClientService.getCountInfo(props.shift)
            .then((res) => {
                if (res.status === 200) {
                    setCountData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            });
    };

    console.log()

    useEffect(() => {
        if (props.shift) {
            getCountData();
        }
    }, [props.shift]);

    // useEffect(() => {
    //     getAllShift();
    // }, []);
    return (
        <>
            {/* <div className="d-flex justify-content-between align-items-center mt-5">

                <h4 className="d-flex text-secondary">CSR Stats</h4>
                <div className="d-flex">
                    <Select
                        options={shiftDate}
                        onChange={(e: any) => setSelectedShiftDate(e)}
                        placeholder="Select shift Date"
                        isClearable
                    />
                    <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectValue(e?.value)}
                        placeholder="Select shift"
                        className="ms-3"
                        isClearable
                    />
                    <Button className="ms-4" size="sm" onClick={getCountData}>Refresh Data <FontAwesomeIcon icon={faRefresh} /></Button>
                </div>
            </div> */}
            <div className='d-flex align-items-center justify-content-center flex-wrap gap-3 mt-3'>
                {countData && typeof countData === 'object'
                    ? Object.entries(countData).map(([key, value]) => (
                        <UsersCard
                            key={key} 
                            reactIcon={
                                <FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />
                            }
                            header={key === "fingerprint" ? "Right Fingerprint": key === "fingerprint2" ? "Left Fingerprint" : key}
                            statsValue={value || 0} 
                        />
                    ))
                    : <p>Please Select Shift First...</p> 
                }
            </div>
        </>
    )
}