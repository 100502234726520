import { faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import VerifiedUserModal from '../modals/VerifiedUser.modal'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import UserModal from '../modals/User.modal'
import { table } from 'console'

interface IFilterTable {
    tableData: any
    shiftId: any
}

const FilterTable = ({ tableData,shiftId }: IFilterTable) => {

    const [showModal, setShowModal] = useState<any>(undefined);

    const [showUserModal, setShowUserModal] = useState<any>(undefined)

    function isUserVerified(user: any) {
        const details = tableData?.find((verified: any) => verified?._id === user?._id);
        if (!details) {
            return false;
        }

        return details.verificationDetails.some((verification: any) => verification.verificationDetails.some((ver: any) => ver.success === true || ver.manuallyVerified === true));
    }


    function hasUserPhoto(user: any) {
        const verificationDetails = tableData && tableData?.verifications?.find((verified: any) => verified?.student?._id === user?._id);
        return verificationDetails?.userLivePhotoExists ? true : false;
    }

    return (
        <>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Name</th>
                        {/* <th>Photo</th> */}
                        <th>Roll.No</th>
                        <th>Verification</th>
                        {/* <th>Mobile</th> */}
                        <th>State</th>
                        <th>City</th>
                        <th>Center_Preference</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tableData && tableData.length > 0 ? (
                        tableData.map((data: any, index: number) => {
                            const verifiedUser = tableData?.verifications?.find((verified: any) => verified?.student?._id === data?._id);
                            return (
                                <tr key={data?._id || index}>
                                    <td>{index + 1}</td>
                                    <td>{data?.name || "--"}</td>
                                    <td>{data?.rollNo || "--"}</td>
                                    <td>
                                        {isUserVerified(data) ? (
                                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                        ) : (
                                            <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />
                                        )}
                                    </td>
                                    <td>{data?.state || "--"}</td>
                                    <td>{data?.city || "--"}</td>
                                    <td>{data?.centerPreference || "--"}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            className="text-primary"
                                            onClick={() => setShowUserModal(data._id)}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={7} className="text-center">
                                <span>No data found</span>
                            </td>
                        </tr>
                    )}
                </tbody>

            </Table>

            <VerifiedUserModal
                show={showModal ? true : false}
                handleClose={() => setShowModal(undefined)}
                modalData={
                    tableData && tableData?.verifications?.find((verified: any) => verified?.studentId === showModal)
                }
            />

            <UserModal
                show={showUserModal}
                handleClose={() => setShowUserModal(undefined)}
                shiftId={shiftId}
            />
        </>
    )
}

export default FilterTable