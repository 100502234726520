import React, { useEffect, useState } from "react";
import { ClientService } from "../../component/services/client.service";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { AgCharts } from "ag-charts-react";
import Select from "react-select"
import { Label } from "recharts";
import moment from "moment";
interface IVerifiedStats {
  shiftId: any;
  selectedCenters: any;
  selectedZones: any;
  selectedDistrict: any;
  selectedStates: any;
  states: any
}

export default function VerifiedStats(props: IVerifiedStats) {
  const [chartOptions, setChartOptions] = useState<any>(null);
  const [overallChartOptions, setOverallChartOptions] = useState<any>(null);
  const [lineChartOptions, setLineChartOptions] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<any>();

  const [barChartOptions, setBarChartsOptions] = useState<any>();
  const [barChartCountOptions, setBarChartsCountOptions] = useState<any>();

  const [selectedStateData, setSelectedStateData] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false)
  const [stateLoading, setStateLoading] = useState<boolean>(false)

  const getVerificationCount = async () => {
    try {
      const payload = {
        state: props.selectedStates?.map((data: any) => data?.value),
        city: props.selectedDistrict?.map((data: any) => data?.value),
        zone: props.selectedZones?.map((data: any) => data?.value),
        centerId: props.selectedCenters?.map((data: any) => data?.value),
      }
      setLoading(true)
      const res = await ClientService.getVerifiedCount(props.shiftId, payload);
      if (res.status === 200) {
        const data = {
          Pending: res.data?.notVerified,
          Verified: res.data?.verified,

        };

        const overall_data = {
          Pending: res.data?.notVerified,
          Verified: res.data?.verified,
          Bypass: res.data?.bypassedUsers,
          Additional_Candidate: res.data?.additionalUsers
        };

        const pieChartData = Object.entries(data).map(([key, value]) => ({
          asset: key,
          amount: value,
          fill: key === "Verified" && "green",
        }));

        const pieChartOverallData = Object.entries(overall_data).map(([key, value]) => ({
          asset: key,
          amount: value,
          fill: key === "Verified" && "green",
        }));

        setChartOptions({
          data: pieChartData,
          title: {
            text: "",
          },
          series: [
            {
              type: "pie",
              calloutLabelKey: "asset",
              angleKey: "amount",
              innerRadiusRatio: 0.7,
              fills: pieChartData?.map((item) => item.fill)
            },
          ],
        });
        setOverallChartOptions({
          data: pieChartOverallData,
          title: {
            text: "",
          },
          series: [
            {
              type: "pie",
              calloutLabelKey: "asset",
              angleKey: "amount",
              innerRadiusRatio: 0.7,
              fills: pieChartOverallData?.map((item) => item.fill)
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error fetching verification count:", error);
    }
    setLoading(false)
  };

  const getVerificationCountStats = async () => {
    try {
      const payload = {
        state: props.selectedStates?.map((data: any) => data?.value),
        city: props.selectedDistrict?.map((data: any) => data?.value),
        zone: props.selectedZones?.map((data: any) => data?.value),
        centerId: props.selectedCenters?.map((data: any) => data?.value),
        intervalMinutes: selectedTime?.value
      }
      setLoading(true)
      const res = await ClientService.getVerificationCountStats(props.shiftId, payload);

      const chart_data = res.data?.map((data: any, index: number) => {
        return {
          verification: data?.percentage,
          time: moment(data?.start).format("DD-MM-YY, hh:mm A")
        }
      })

      console.log(chart_data, "chart_data")

      setLineChartOptions({
        title: {
          text: "",
        },
        data: chart_data,
        series: [
          {
            type: "line",
            xKey: "time",
            yKey: "verification",
            yName: "Verification Percentage (%)",
            strokeWidth: 4,
            marker: {
              enabled: true,
            },
            interpolation: { type: "smooth" },
          },
        ],
      });
    } catch (error) {
      console.error("Failed to fetch verification count stats:", error);
    }
    setLoading(false)
  };

  const getVerificationByState = async () => {
    try {
      const payload = {
        states: selectedStateData?.map((data: any) => data?.value) || []
      }
      setStateLoading(true)
      const res = await ClientService.getVerificationByState(props.shiftId, payload);

      setBarChartsOptions({
        title: {
          text: "",
        },
        data: res.data,
        series: [
          {
            type: "bar",
            xKey: "state",
            yKey: "verificationPercentage",
            yName: "Verification Percentage",
          },
        ],

      });

      setBarChartsCountOptions({
        title: {
          text: "",
        },
        data: res.data,
        series: [
          {
            type: "bar",
            xKey: "state",
            yKey: "totalUsers",
            yName: "Total Users",
          },
          {
            type: "bar",
            xKey: "state",
            yKey: "verifiedCount",
            yName: "Verified Count",
          },
          {
            type: "bar",
            xKey: "state",
            yKey: "uniqueVerifiedUsers",
            yName: "Unique Verified Count",
          },
        ],

      });
    } catch (error) {
      console.error("Failed to fetch verification count stats:", error);
    }
    setStateLoading(false)
  };


  const time_interval = [
    {
      label: "15 min",
      value: 15
    },

    {
      label: "30 min",
      value: 30
    },
    {
      label: "45 min",
      value: 45
    },
    {
      label: "1 Hour",
      value: 60
    },
    {
      label: "90 min",
      value: 90
    },
    {
      label: "2 Hour",
      value: 120
    },
  ]

  useEffect(() => {
    setSelectedTime(time_interval[0])
  }, [])

  useEffect(() => {
    if (props.shiftId) {
      getVerificationCountStats();
      getVerificationCount();
    }
  }, [props.shiftId, selectedTime, props.selectedCenters, props.selectedDistrict, props.selectedStates, props.selectedZones]);

  useEffect(() => {
    if (props.shiftId) {
      getVerificationByState()
    }
  }, [props.shiftId, selectedStateData]);

  return (
    <>
      {loading &&
        <div className="d-flex justify-content-center align-items-center my-3">
          <Spinner animation="border" />
        </div>
      }
      <Row>
        <Col>
          <Card
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "12px",
              border: "none",
            }}
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h6>Timely Verification Trends</h6>
                </div>
                <div>
                  <Select
                    options={time_interval}
                    className=""
                    value={selectedTime}
                    onChange={(e: any) => setSelectedTime(e)}
                  />
                </div>
              </div>
              {lineChartOptions ? (
                <AgCharts options={lineChartOptions} style={{ height: 340 }} />
              ) : (
                <p></p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "12px",
              border: "none",
            }}
          >
            <Card.Body>
              <div className="d-flex justify-content-start mb-4">
                <h6>Verification Statistics</h6>
              </div>
              {chartOptions ? (
                <AgCharts options={chartOptions} style={{ height: 340 }} />
              ) : (
                <p></p>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "12px",
              border: "none",
            }}
          >
            <Card.Body>
              <div className="d-flex justify-content-start mb-4">
                <h6>Overall Verification Statistics</h6>
              </div>
              {chartOptions ? (
                <AgCharts options={overallChartOptions} style={{ height: 340 }} />
              ) : (
                <p></p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "12px",
              border: "none",
            }}
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h6>State Wise Verification (%)</h6>
                </div>
                <div className = "d-flex align-items-center gap-3">
                  <Select
                    options={props.states}
                    className=""
                    isMulti
                    onChange={(e: any) => setSelectedStateData(e)}
                  />
                  {stateLoading &&
                    <div className="d-flex justify-content-center align-items-center my-3">
                      <Spinner animation="border" />
                    </div>
                  }
                </div>
              </div>
              {lineChartOptions ? (
                <AgCharts options={barChartOptions} style={{ height: 340 }} />
              ) : (
                <p></p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "12px",
              border: "none",
            }}
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h6>State Wise Verification (count)</h6>
                </div>
              </div>
              {lineChartOptions ? (
                <AgCharts options={barChartCountOptions} style={{ height: 340 }} />
              ) : (
                <p></p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>

  );
}
